<template>
  <div id="mainGraphPane" ref="mainGraphPane">
    <LoadingIcon
      v-if="$store.state.quests.is_quest_graph_loading"
      style="margin-left: 90%"
    />
    <button @click="saveMyGraph" id="quest_graph_save_btn">Save</button>
    <div ref="questNode"></div>
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import G6 from '@antv/g6';
import dendroConfig from '@/config/dendroConfig';
import {
  questContextMenu,
  questGraphToolBar,
} from '@/common/widgets/GraphExploreWidget/graphPlugins';
import LoadingIcon from '../../components/LoadingIcon.vue';

export default {
  data() {
    return {
      context: '',
    };
  },

  computed: {
    quest_data() {
      return this.$store.state.quests.quest_graph_data;
    },
    goals() {
      return this.$store.state.goals.goals;
    },
  },

  watch: {
    async goals() {
      await this.updateGoalNodeStatus();
    },
    async quest_data(val) {
      // console.log(val);
      this.quest_graph.changeData(val);
      this.quest_graph.edge((ev) => ({
        type: 'cubic-horizontal',
        color: '#A3B1BF',
        label:
          this.$store.state.quests.quest_graph_edges[
            `${ev.source}${ev.target}`
          ],
      }));

      await this.updateGoalNodeStatus();
    },
  },

  async mounted() {
    new ResizeObserver(this.resizeLayout).observe(this.$refs.mainGraphPane);
    await this.dendroGraphBuild();
  },

  async created() {
    await this.$highway.singleDispatcher('quests/questGraphAction').then(() => {
      this.quest_graph.changeData(this.quest_data);
    });
  },

  components: {
    LoadingIcon,
  },

  methods: {
    async dendroGraphBuild() {
      this.config = dendroConfig;
      this.config.container = this.$refs.questNode;
      this.config.plugins = [questContextMenu, questGraphToolBar];
      this.quest_graph = new G6.TreeGraph(this.config);

      this.quest_graph.node((node) => ({
        labelCfg: {
          position:
            node.children && node.children.length > 0 ? 'left' : 'right',
          offset: 5,
          style: {
            fontSize: 15,
            fill: '#fff',
            fontWeight: 500,
            fontFamily: 'Poppins',
            color: '#fff',
          },
        },
      }));

      /**
       * Event Listeners
       */

      // Node Click
      this.quest_graph.on('node:click', this.graphNodeClick);

      /** ************************************************************* */

      this.quest_graph.edge((ev) => ({
        type: 'cubic-horizontal',
        color: '#A3B1BF',
        label:
          this.$store.state.quests.quest_graph_edges[
            `${ev.source}${ev.target}`
          ],
      }));

      this.quest_graph.render();
      this.quest_graph.fitView();
    },

    async saveAndUpdateData() {
      try {
        // Save the graph data once to a variable
        const graphData = this.quest_graph.save();
        // Saves the graph data to the store
        this.$store.dispatch('quests/setQuestGraphData', graphData);
        // Get the active graph id
        const activeGraphId = this.$store.state.active_graph_id.quest_library;
        // Saves the graph data to the database
        await this.$store.dispatch('gmodule/updateGraph', {
          id: activeGraphId,
          gdata: graphData,
        });
      } catch (error) {
        console.error('Error while saving and updating data: ', error);
      }
    },

    async saveMyGraph() {
      try {
        const payloadObj = {
          graph_name: this.quest_data.label,
          gdata: this.quest_data,
          graph_type: ['Uncategorized'],
          graph_tags: '',
          graph_access: 'public',
          graph_dimension: 'quest_library', // this basically decides whether its going to be in quest library or graph library
        };
        await this.$store.dispatch('saveCurrentGraph', payloadObj);
        // trigger event on Saving quest;
        const eventPayload = {
          verb: 'SAVED_ON_GOING_QUEST',
          value: {
            title: payloadObj.graph_name,
          },
        };
        this.$store.dispatch('eventToEventStore', eventPayload);
      } catch (error) {
        console.error('Error while saving graph: ', error);
      }
    },

    async updateGoalNodeStatus() {
      const nodeWithGoals = this.quest_graph.findAll('node', (nodeGoal) => {
        const nodeModelForGoal = nodeGoal.get('model');
        return nodeModelForGoal.goal;
      });

      const goalIds = nodeWithGoals.map(
        (nodeGoal) => nodeGoal.get('model').goal,
      );

      const goalsDetails = await this.$store.dispatch(
        'goals/fetchGoalsWithIds',
        goalIds,
      );
      const completedGoals = goalsDetails
        .filter((goal) => goal.status)
        .map((goal) => goal.uid);
      // console.log('completedGoals', completedGoals);
      const completedGoalsNodes = this.quest_graph.findAll(
        'node',
        (nodeGoal) => {
          const nodeModelForGoal = nodeGoal.get('model');
          return completedGoals.includes(nodeModelForGoal.goal);
        },
      );

      completedGoalsNodes.forEach((nodeGoal) => {
        nodeGoal.update({
          style: {
            fill: '#002fff',
            stroke: '#fff',
          },
        });
      });

      await this.saveAndUpdateData();
    },

    resizeLayout() {
      // console.log('resizeLayout', this.$refs.mainGraphPane.clientHeight, this.$refs.mainGraphPane.clientWidth);
      this.quest_graph.changeSize(
        this.$refs.mainGraphPane.clientWidth,
        this.$refs.mainGraphPane.clientHeight,
      );
      this.quest_graph.layout();
    },

    _popuLateContext(item) {
      if (item._cfg.parent) {
        // console.log('Parent', item._cfg.parent);
        this.context += `${item._cfg.parent._cfg.model.label
          .trim()
          .split(' ')
          .filter((itemEl, index) => {
            if (index === 0) {
              return Boolean(Number(itemEl)) === false;
            }
            return itemEl;
          })
          .join(' ')} /`;
        // console.log('this.context Populate', this.context);
        this._popuLateContext(item._cfg.parent);
      }
    },

    async _questModeNodeClick(nodeItem, wordObj) {
      this.context = '';
      this._popuLateContext(nodeItem);

      nodeItem.update({
        style: {
          fill: '#fcba03',
          stroke: '#fff',
        },
      });

      // eslint-disable-next-line no-param-reassign
      wordObj.context = this.context;

      const nodeDescriptionInfo = await this.$store.dispatch(
        'quests/fetchQuestNodeInfo',
        wordObj,
      );

      nodeItem.update({
        style: {
          fill: '#52c400',
          stroke: '#fff',
        },
      });

      return nodeDescriptionInfo;
    },

    async _exploreModeNodeClick(nodeModel, nodeItem) {
      const { id } = nodeModel;
      const eventData = { id: nodeModel.id, word: nodeModel.label };
      const QID = nodeModel.custom_id || id;

      // const processNode = this.quest_graph.findById(id);
      nodeItem.update({
        style: {
          fill: '#59f783',
          stroke: '#fff',
        },
      });

      const response = await this.$store.dispatch(
        'quests/fetchQuestExploreInfo',
        QID,
      );

      await this.$store.dispatch('quests/expandQuestBranch', response);
      const dataChild = response.nodes.slice(1, 10).map((el) => ({
        // eslint-disable-next-line
        id: `${nodeModel.id}${el.id}`,
        label: el.label,
        custom_id: el.id,
      }));

      await this.quest_graph.updateChildren(dataChild, id);
      const updatedNode = this.quest_graph.findById(id);

      updatedNode.update({
        style: {
          fill: '#ffc107',
          stroke: '#fff',
        },
      });

      await this.$store.dispatch(
        'quests/setQuestGraphData',
        this.quest_graph.save(),
      );

      const eventPayload = {
        verb: 'EXPLORED',
        value: eventData,
      };
      this.$store.dispatch('eventToEventStore', eventPayload);
    },

    async graphNodeClick(node) {
      const nodeItem = node.item;
      const nodeModel = nodeItem._cfg.model;
      // console.log(nodeModel);
      // custom_id will have an accurate id of an entity
      // eslint-disable-next-line
      const wordObj = {
        word: nodeModel.label,
        label: nodeModel.label,
        tjModel: nodeModel,
        type: 'string',
        lang: this.$store.state.locale.currentLocale,
      };
      // eslint-disable-next-line

      if (this.$store.state.quests.quest_mode) {
        // console.log('Quest Mode');
        if (!nodeModel.description) {
          nodeModel.description = await this._questModeNodeClick(
            nodeItem,
            wordObj,
          );
          await this.saveAndUpdateData();
        }
      } else {
        console.log('Explore Mode');
        if (nodeModel.tjType !== 'openai_node') {
          await this._exploreModeNodeClick(nodeModel, nodeItem);
        }
      }

      // this.$store.commit('SET_CURRENT_ENTITY', wordObj);
      await this.$store.dispatch('setCurrentThought', {
        thoughtObject: wordObj,
        excludeCallList: ['quests/questGraphAction'],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  font-family: "Helvetica", sans-serif;
}

.g6-component-contextmenu {
  position: absolute;
  list-style-type: none;
  padding: 10px 8px;
  left: -150px;
  background-color: #333 !important;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  font-size: 12px;
  color: #fff !important;
}
.g6-component-contextmenu li {
  cursor: pointer;
  list-style-type: none;
  list-style: none;
  margin-left: 0px;
}
.g6-component-contextmenu li:hover {
  color: #aaa;
}
#quest_graph_save_btn {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100;
  opacity: 0.5;
  border-radius: 5px;
  border: none;
  transition: all 0.3s ease-out;
  &:hover {
    opacity: 1;
    background: #010148;
    color: #fff;
  }
}

/* #container {
  width: 85.3125em;
  height: 88vh;
  position: relative;
} */
</style>
