export default {
  container: 'container',
  width: 1500,
  height: 650,
  animate: true,
  fitView: false,
  modes: {
    default: [
      // {
      //   type: 'collapse-expand',
      //   onChange: function onChange(item, collapsed) {
      //     const data = item.get('model');
      //     data.collapsed = collapsed;
      //     return true;
      //   },
      //   trigger: 'ctrl + click',
      // },
      'drag-canvas',
      'zoom-canvas',
    ],
  },
  defaultNode: {
    size: 20,
    anchorPoints: [
      [0, 0.5],
      [1, 0.5],
    ],
    style: {
      stroke: '#010148',
      lineWidth: 1,
    },
  },
  defaultEdge: {
    type: 'cubic-horizontal',
    // label: 'Game',
    labelCfg: {
      style: {
        fill: '#fff',
        fontSize: 12,
        fontWeight: 500,
        fontFamily: 'Poppins',
        color: '#fff',
      },
    },
  },
  layout: {
    type: 'dendrogram',
    direction: 'LR', // H / V / LR / RL / TB / BT
    nodeSep: 50,
    rankSep: 400,
    center: [1000, 500], // The center of the graph by default
  },
};
